import { IncomingMessage } from 'http';

import { isOurKey } from '@swe/shared/providers/persist-state/constants';
import CookieStorage from '@swe/shared/providers/persist-state/cookie-storage';
import { RouteQuery } from '@swe/shared/providers/router/constants';

import { getCookie, getCookies } from '@swe/shared/tools/cookie';
import { Theme } from '@swe/shared/ui-kit/theme/provider/themes';
import { pickByKey } from '@swe/shared/utils/object';

import { loadTheme } from './theme';

import { AppSettings } from '@swe/shop-ui/app/types';
import { AppRuntimeConfig } from '@swe/shop-ui/runtime-config';
import { getClientWidthFallback } from 'app/utils';
import { createStoreIdBasePath, getStoreIdFromBasePath } from 'common/router/utils';
import { getSaleType, SALE_TYPE_STORAGE_KEY } from 'common/use-cases/use-sale-type';
import GetShopConfigEndpoint from 'endpoints/shop/get-shop-config';
import GetShopInfoEndpoint from 'endpoints/shop/get-shop-info';

import { ShopConfigDTO, Translations } from 'entities/shop/config';
import { StoreSaleType } from 'entities/shop/sale-type';

const parseTranslations = (translations: string): Translations => {
  try {
    return JSON.parse(translations);
  } catch (e) {
    return {};
  }
};

const BASE_PATH_PATTERNS = [
  /^\/(locations\/[\d\-A-Za-z]+\/((medical|recreational)-)?menu)/,
  /^\/(locations\/[\d\-A-Za-z]+\/[\d\-A-Za-z]+\/shop)/,
  /^\/(stores\/[\d\-A-Za-z]+\/[\d\-A-Za-z]+\/shop)/,
  /^\/(stores\/[\d\-A-Za-z]+\/[\d\-A-Za-z]+)/,
  /^\/(shop\/[\d\-A-Za-z]+\/[\d\-A-Za-z]+)/,
  /^\/(shop\/[\d\-A-Za-z]+)/,
];

const CURALEAF_BASE_PATH_PATTERNS = [/^\/(shop\/[\d\-A-Za-z]+\/[\d\-A-Za-z]+)/];
const SUPERGOOD_BASE_PATH_PATTERNS = [/^\/(stores\/[\d\-A-Za-z]+)/];

const HOST_TO_BASE_PATH_PATTERNS: Record<string, RegExp[]> = {
  'supergoodstore.com': SUPERGOOD_BASE_PATH_PATTERNS,
  'curaleaf.com': CURALEAF_BASE_PATH_PATTERNS,
};

const getBasePathPatternsForHost = (host: string) => HOST_TO_BASE_PATH_PATTERNS[host] ?? BASE_PATH_PATTERNS;

const DEVICE_TYPE_HEADER = 'CF-Device-Type';

const isRedirect = (error: any): error is Response => {
  return error instanceof Response && 'status' in error && [301, 302, 303, 307, 308].includes(error.status);
};

const isStoreNotFound = (error: any): error is { status: 404 } => {
  return error && typeof error === 'object' && 'status' in error && error.status === 404;
};

const resolveStore = async (url: URL): Promise<ShopConfigDTO> => {
  const { pathname, host, search } = url;
  let basePath = pathname.split('/')[1] ?? '';
  const basePathPatterns = getBasePathPatternsForHost(host);
  for (let i = 0; i < basePathPatterns.length; i++) {
    const [, customBasePath] = pathname.match(basePathPatterns[i]) ?? [];
    if (customBasePath) {
      basePath = customBasePath;
      break;
    }
  }

  const storeIdFromBasePath = getStoreIdFromBasePath(basePath);

  const config = await GetShopConfigEndpoint.request(storeIdFromBasePath ? {} : { domain: host, routeName: basePath }, {
    headers: { StoreId: (storeIdFromBasePath || -1).toString() },
  });

  const { routeName } = config;

  if (!storeIdFromBasePath && basePath !== routeName) {
    throw new Response('', { status: 302, headers: { Location: `/${routeName}${pathname}${search}` } });
  }

  return {
    ...config,
    routeName: `/${storeIdFromBasePath ? createStoreIdBasePath(storeIdFromBasePath) : routeName}`,
  };
};

const getSaleTypeFromReq = (medicalMenuEnabled: boolean, cookie?: string | Record<string, string | undefined>) => {
  const storagesFallback = {
    cookies: pickByKey(getCookies({ cookie }), isOurKey),
  };

  return getSaleType(
    medicalMenuEnabled,
    new CookieStorage(storagesFallback.cookies).getItem<Exclude<StoreSaleType, undefined>>(SALE_TYPE_STORAGE_KEY),
  );
};

const prepareAppSettings = async ({
  reqHeaders,
  storeConfig,
  query = {},
  runtimeConfig,
}: {
  reqHeaders: IncomingMessage['headers'];
  storeConfig: ShopConfigDTO;
  query?: RouteQuery;
  runtimeConfig: AppRuntimeConfig;
}): Promise<AppSettings> => {
  const { assetPrefix, posthogConfig } = runtimeConfig;
  const reqUserAgent = reqHeaders['user-agent'];
  const deviceTypeHeader = reqHeaders[DEVICE_TYPE_HEADER];
  const deviceType = Array.isArray(deviceTypeHeader) ? deviceTypeHeader[0] : deviceTypeHeader;
  const clientWidthFallback = getClientWidthFallback({ userAgent: reqUserAgent, deviceType });

  const { id, theme, translations } = storeConfig!;

  const headersToForward: Record<string, string> = {
    StoreId: id.toString(),
  };

  const storagesFallback = {
    cookies: pickByKey(getCookies({ cookie: reqHeaders.cookie }), isOurKey),
  };

  const reqHasAuthCookie = !!getCookie('UserLoginCookie', { cookie: reqHeaders.cookie });

  const themeName = (query['__sw-theme'] as Theme) ?? theme;
  const [themeBuild, shopInfo] = await Promise.all([
    loadTheme(themeName, assetPrefix),
    GetShopInfoEndpoint.request(undefined, { headers: headersToForward }),
  ] as const);

  // TODO: hardcoded cleaning of hydration data, remove after store info and config optimization
  // eslint-disable-next-line no-param-reassign
  storeConfig.termsOfService = 'Loading...';
  // eslint-disable-next-line no-param-reassign
  storeConfig.privacyPolicy = 'Loading...';
  if (shopInfo && shopInfo.deliveryZones) {
    shopInfo.deliveryZones = [];
  }

  return {
    storeId: id,
    endpointsFallback: {
      [GetShopInfoEndpoint.key()]: shopInfo,
      [GetShopConfigEndpoint.key({})]: storeConfig,
    },
    translations: translations ? parseTranslations(translations) : {},
    clientWidthFallback,
    reqHasAuthCookie,
    themeBuild,
    storagesFallback,
    reqUserAgent,
    basePath: storeConfig.routeName as `/${string}`,
    analyticsConfig: { posthog: posthogConfig },
    runtimeConfig,
  };
};

export { prepareAppSettings, getSaleTypeFromReq, resolveStore, isRedirect, isStoreNotFound };
